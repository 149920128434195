import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { GoTriangleLeft } from "react-icons/go"
import SEO from "../components/SEO"

export const query = graphql`
  query($slug: String!) {
    garden: mdx(
      frontmatter: { slug: { eq: $slug }, type: { eq: "GARDEN_NOTE" } }
    ) {
      frontmatter {
        title
        tags
      }
      body
    }
  }
`

const gardenTemplate = ({ data }) => {
  const { title } = data.garden.frontmatter
  const { body } = data.garden
  return (
    <Layout>
      <SEO title={title} />
      <Wrapper>
        <AniLink fade className="breadcrumb" to="/garden">
          <GoTriangleLeft />
          <span> All Items </span>
        </AniLink>
        <div className="garden">
          <h1>{title}</h1>
          <div className="garden-body">
            <MDXRenderer>{body}</MDXRenderer>
          </div>
        </div>
        <AniLink fade className="breadcrumb" to="/">
          <GoTriangleLeft />
          <span> All Items </span>
        </AniLink>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ul,
  ol {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    li {
      margin-bottom: 0.4em;
    }
  }

  hr {
    border: 0.5px solid var(--primaryColor);
    width: 50%;
    margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  h1 {
    text-align: center;
  }

  .more {
    margin-top: 2em;
  }

  .breadcrumb {
    margin: 2em 0 2em 2em;
    list-style-type: none;
    transition: var(--mainTransition);
    color: var(--mainBlack);

    svg {
      font-size: 0.8rem;
    }
  }

  .garden-body {
    padding: 0 10%;
    text-align: left;
    font-size: 1.04em;
    margin: 1em auto 0px;
    align-items: flex-start;
    margin: 0 auto;

    ul,
    ol {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;

      li {
        margin-bottom: 0.4em;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .garden-body {
      padding: 0 1em;
    }
  }
`

export default gardenTemplate
